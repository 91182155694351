import axios from 'axios';

const STORAGE_BASE_URL = process.env.REACT_APP_STORAGE_BASE_URL;

export function getByName(name) {
  return axios({
    url: `${STORAGE_BASE_URL}${name}`,
    method: 'get',
  })
    .then((response) => {
      return response.data;
    });
}

export default { getByName };
