import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkHeadingId from 'remark-heading-id';
import remarkGfm from 'remark-gfm';
import './styles.scss';

export default function MarkdownViewer({ content }) {
  return (
    <ReactMarkdown remarkPlugins={[remarkGfm, remarkHeadingId]}>{content}</ReactMarkdown>
  );
}
