/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './styles.scss';
import remarkHeadings from '@vcarl/remark-headings';
import { unified } from 'unified';
import remarkParse from 'remark-parse';
import remarkStringify from 'remark-stringify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Toaster, toast } from 'react-hot-toast';

export default function MarkdownSections({ content }) {
  const [titles, setTitles] = useState([]);
  const location = useLocation();
  const urlMdGrava = process.env.REACT_APP_URL;
  const generalPath = `${urlMdGrava}${location.pathname}`;

  async function processTitles() {
    const processor = unified()
      .use(remarkParse)
      .use(remarkStringify)
      .use(remarkHeadings);
    const titulos = await processor.process(content);
    return titulos;
  }

  useEffect(async () => {
    processTitles()
      .then((vfile) => {
        setTitles(vfile.data.headings);
      });
  }, []);

  function notify() {
    toast('Copiado en el portapapeles');
  }

  function renderTitles() {
    const tags = [];
    titles.forEach((title, indexTitle) => {
      const idText = title.value.slice(title.value.indexOf('{') + 1, title.value.indexOf('}'));
      const elementText = title.value.slice(0, title.value.indexOf('{'));
      const index = title.value.slice(title.value.indexOf('{') + 1, title.value.indexOf('}'));
      if (title.depth === 2) {
        tags.push((
          <li key={`index-${indexTitle}`}>
            <CopyToClipboard text={generalPath + idText}>
              <a onClick={notify} key={title.value} href={index}>{elementText}</a>
            </CopyToClipboard>
          </li>
        ));
      } else if (title.depth === 3) {
        tags.push((
          <ul key={`index-depth3-${indexTitle}`}>
            <li key={`index-${indexTitle}`}>
              <CopyToClipboard text={generalPath + idText}>
                <a onClick={notify} key={title.value} href={index}>{elementText}</a>
              </CopyToClipboard>
            </li>
          </ul>
        ));
      }
    });
    return tags;
  }
  return (
    <>
      <ul>
        {renderTitles()}
      </ul>
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: '',
          duration: 2000,
          style: {
            background: '#363636',
            color: '#fff',
          },
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
      />
    </>
  );
}
