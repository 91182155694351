import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DocViewer from './pages/doc-viewer';
import NotFound from './pages/not-found';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route exact path=":docname" element={<DocViewer />} />
        <Route exact path="/notfound" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
