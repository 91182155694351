import React from 'react';
import Gif from '../../assets/notfound.gif';
import './styles.scss';

export default function NotFound() {
  return (
    <div className="error-page">
      <img alt="" src={Gif} />
      <h2>Documento no encontrado</h2>
    </div>
  );
}
