import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import './styles.scss';
import { Toaster, toast } from 'react-hot-toast';
import { getByName } from '../../services/document';
import MarkdownViewer from '../../components/markdown-viewer';
import MarkdownSections from '../../components/markdown-sections';

export default function DocViewer() {
  const { docname } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [docContent, setDocContent] = useState();
  const sampleLocation = useLocation();
  const urlMdGrava = process.env.REACT_APP_URL;
  const generalPath = `${urlMdGrava}${sampleLocation.pathname}`;

  function scrolllingToElement() {
    const id = sampleLocation.hash.replace('#', '');
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  useEffect(() => {
    scrolllingToElement();
  }, [sampleLocation]);

  useEffect(() => {
    getByName(docname)
      .then((res) => {
        setDocContent(res);
        setLoading(false);
      })
      .then(() => {
        scrolllingToElement();
      })
      .then(() => {
        const titulos = document.getElementsByTagName('h2');
        for (let index = 0; index < titulos.length; index++) {
          const element = titulos[index];
          element.onclick = function () {
            navigator.clipboard.writeText(`${generalPath}#${element.id}`);
            toast('Copiado en el portapapeles');
          };
        }
      })
      .catch((reserr) => {
        console.log(reserr);
        return navigate('/notfound');
      });
  }, [docname]);

  if (loading) {
    return <div className="loader">Loading...</div>;
  }
  return (
    <div className="doc-viewer">
      <div className="header-box">
        <h1>{docname}</h1>
      </div>
      <div className="content">
        <div className="nav-box">
          <MarkdownSections content={docContent} />
        </div>
        <div className="content-box">
          <MarkdownViewer content={docContent} />
        </div>
      </div>
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: '',
          duration: 2000,
          style: {
            background: '#363636',
            color: '#fff',
          },
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
      />
    </div>
  );
}
